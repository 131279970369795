import { Component, OnInit, Renderer } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CallhttpService } from 'app/callhttp.service';

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styles: [`
    ngb-progressbar {
        margin-top: 5rem;
    }
    .carousel-inner .carousel-item img{
        border-radius: 0px !important;
        height: 100%;
    }
    `]
})

export class ComponentsComponent implements OnInit {
    page = 4;
    page1 = 5;
    focus;
    date: { year: number, month: number };
    model: NgbDateStruct;

    slide: any = [
        // {
        //     image: "./assets/img/BannerThonglor01.jpg",
        //     url: "https://www.google.com/",
        // }
    ]

    constructor(private renderer: Renderer,private callhttpService : CallhttpService) { }
    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: { month: number }) {
        return date.month !== current.month;
    }

    ngOnInit() {
        let input_group_focus = document.getElementsByClassName('form-control');
        let input_group = document.getElementsByClassName('input-group');
        for (let i = 0; i < input_group.length; i++) {
            input_group[i].children[0].addEventListener('focus', function () {
                input_group[i].classList.add('input-group-focus');
            });
            input_group[i].children[0].addEventListener('blur', function () {
                input_group[i].classList.remove('input-group-focus');
            });
        }

        this.listBaner();
    }

    listBaner(){
        this.callhttpService.getTracking("masterfile", {
            table : "tab_banner",
        }).subscribe((res: any) => {
            if (res.status) {
                this.slide = res.data;
            } else {
                alert(res.message);
            }
        });
    }

}
