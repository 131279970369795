import { Component, OnInit, Inject, Input, ViewChild, NgZone } from '@angular/core';
import { CallhttpService } from 'app/callhttp.service';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { async } from '@angular/core/testing';
import { MapsComponent } from 'app/maps/maps.component';
import { MapsAPILoader } from '@agm/core';
import { ReviewComponent } from 'app/review/review.component';



@Component({
  selector: 'app-modal-content',
  template: `
  <div class="modal-header modal-lg">
      <h5 class="modal-title text-center">{{clinicName}}</h5>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body"> 
  <img [src]="urlImg"  class="img-thumbnail img-responsive box-shadow-0" alt="Rounded Image" >
  </div>
  <div class="modal-footer">
      <div class="divider"></div>
      <div class="right-side">
          <button type="button" class="btn btn-danger btn-link" (click)="activeModal.close('Close click')">ปิด</button>
      </div>
  </div>
  `
})
export class NgbdModalContent {
  @Input() urlImg;
  @Input() clinicName;
  constructor(public activeModal: NgbActiveModal) {
    console.log(this.clinicName);
  }
}



@Component({
  selector: 'app-modal-content',
  template: `
  <div class="modal-header modal-lg " style="background-color: #55a88e;">
      <h5 class="modal-title text-center text-white">โปรโมชั่น</h5>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body"> 
    <div class="form-group row">
      <div class="col-sm-4"></div>
      <div class="col-sm-4"> <img [src]="urlImg"  class="img-thumbnail img-responsive box-shadow-0" alt="Rounded Image" ></div>
    </div>
    <div class="tim-title mt-2 mb-2">
           <h5 class="mt-2">{{item.topic}}</h5>
    </div>
    <div class="tim-title mt-2 mb-2">
        <h5 class="mt-2">รายละเอียด</h5>
    </div>

    <div class="form-group  row">
      <h6 class="col-md-12 mt-2">
          {{item.detail}}
      </h6>
    </div>
    <div class="tim-title mt-2 mb-2">
      <h5>code : {{code}}</h5>
    </div>
  </div>
  <div class="modal-footer">
    <div class="left-side">
          <button type="button" class="btn btn-danger btn-link" (click)="useCode(item)">ใช้งาน Code</button>
      </div>
      <div class="divider"></div>
      <div class="right-side">
          <button type="button" class="btn btn-danger btn-link" (click)="activeModal.close('Close click')">ปิด</button>
      </div>
  </div>
  `
})
export class NgbdModalPromotionContent {
  @Input() urlImg;
  @Input() item;

  code;
  constructor(public activeModal: NgbActiveModal, private callService: CallhttpService, ) {
    this.code = "XXXX";
    this.getUuid()
  }

  private getUuid() {
    this.callService.callService("api/getuuid", {}).subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        this.code = res.data.toUpperCase();
      }
    });
  }
  useCode(item) {
    if (!sessionStorage.getItem('currentUser')) {
      alert("กรุณาล็อกอินเข้าระบบ");
      this.activeModal.close();
    }
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.callService.callService("api/promotion/codeadd", {
      uuid: this.item.uuid,
      promoId: this.item.id,
      code: this.code,
      uid: user.uid
    }).subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        this.activeModal.close();
      }
    });

  }
}


@Component({
  selector: 'app-special-search',
  templateUrl: './special-search.component.html',
  styleUrls: ['./special-search.component.scss']
})
export class SpecialSearchComponent implements OnInit {
  @ViewChild('child') child: MapsComponent;
  latitude: number = 13.7550437;
  longitude: number = 100.529726;
  zoom: number = 6;
  address: string;
  alphabeticLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  pagination = {
    'pageSize': 5,
    'collectionSize': 0,
    'page': 1
  };
  search: any = {
    clinicName: "",
    clinicProvince: "",
    listService: "",
    isActive: "Y",
  };

  pageNum = 0;
  pageSize = 5;
  collectionSize = 0;

  isOpenMap: boolean = false;
  inputLatlng: any = {
    "latitude": 13.7550437,
    "longitude": 100.529726
  }
  data: any;
  listService = [
    {
      name: "GENERAL DENTISTRY/ทันตกรรมทั่วไป, ขุดหินปูน, เคลือบฟลูออไรด์, ขัดฟัน",
      value: '"service_1":"Y"'
    },
    {
      name: "PEDODONTICS/ทันตกรรมสำหรับเด็ก",
      value: '"service_2":"Y"'
    },
    {
      name: "ORTHODONTICS/ทันตกรรมจัดฟัน",
      value: '"service_3":"Y"'
    },
    {
      name: "COSMETIC/ทันตกรรมเพื่อความงาม",
      value: '"service_4":"Y"'
    },
    {
      name: "PROSTHODONTICS/ทันตกรรมใส่ฟันทดแทน ฟันปลอม",
      value: '"service_5":"Y"'
    },
    {
      name: "IMPLANTS/ทันตกรรมรากเทียม",
      value: '"service_6":"Y"'
    },
    {
      name: "PERIODONTICS/ทันตกรรมโรคเหงือกปริทันต์",
      value: '"service_7":"Y"'
    },{
      name: "ENDODONTICS/ทันตกรรมรักษารากฟัน",
      value: '"service_8":"Y"'
    },
    {
      name: "ORAL SURGERY/ศัลยกรรมช่องปาก",
      value: '"service_9":"Y"'
    },
    {
      name: "3D SCAN/สแกน 3 มิติ",
      value: '"service_11":"Y"'
    },
    {
      name: "MAXILLOFACIAL SURGERY/ทันตกรรมผ่าตัดกระดูกขากรรไกรและใบหน้า",
      value: '"service_10":"Y"'
    },
    {
      name: "CLEAR ALIGNER/จัดฟันใส",
      value: '"service_12":"Y"'
    }
  ]



  previewimg: string = "previewimg?table=tab_clinic_img&keyname=uuid&feildname=fileName&keyvalue=";
  urlImagePromotion: string = "previewimg?table=tab_clinic_promotion&keyname=id&feildname=fileName&keyvalue=";
  urlImageList: string = "previewimg?table=tab_clinic_img&keyname=id&feildname=fileName&keyvalue=";
  urlImage: string;
  show: boolean;
  constructor(private router: Router, private route: ActivatedRoute,
    private callService: CallhttpService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private modalService: NgbModal,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) {
    this.urlImage = config.urlApi + this.previewimg;
    this.urlImagePromotion = config.urlApi + this.urlImagePromotion;
    this.urlImageList = config.urlApi + this.urlImageList;
    this.show = true;
  }


  currentUser: any;
  isLogin: boolean = false;
  service: string = "";
  all:string;
  ngOnInit() {

    this.search.clinicName = this.route.snapshot.paramMap.get('clinicName');
    this.search.clinicProvince = this.route.snapshot.paramMap.get('clinicProvince');
    this.service = this.route.snapshot.paramMap.get('service');
    this.all = this.route.snapshot.paramMap.get('all');
    this.pageSize = this.all=='Y'?1000:this.pagination.pageSize;
    // if (this.route.snapshot.paramMap.get('service')) {
    //   this.search.listService = this.listService[this.route.snapshot.paramMap.get('service')]['value'];
    // }
    if (sessionStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
      // this.isLogin = true;
    }
    this.isLogin = true;
    this.find();
  }

  markers: any[] = [];
  find() {
    window.scrollTo(0, 0);
    this.pageNum = this.pageNum == 1 ? 0 : this.pageNum;
    if (this.service && this.service != '') {
      this.search.listService = this.listService[this.service]['value'];
    }
    this.callService.callService("api/clinic/find", {
      search: this.search,
      pageNum: this.pageNum,
      pageSize: this.pageSize
    }).subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        this.data = res.data.data;
        this.collectionSize = res.data.totalItem;
        let i = 0;
        this.markers = [];
        this.data.forEach(item => {
          this.data[i].openDay = JSON.parse(this.subStringStartEnd(item.openDay));
          this.data[i].listService = JSON.parse(this.subStringStartEnd(item.listService));
          if (item.latitude) {
            this.markers.push({
              "item":item,
              "latitude": item.latitude,
              "longitude": item.longitude,
              // "url" : "http://maps.gstatic.com/mapfiles/markers2/icon_green.png",
              "label": this.alphabeticLabels[i % this.alphabeticLabels.length]
            });
          }

          i++;
        });
        console.log(this.markers);
        if (this.markers.length > 0) {
          this.latitude = this.markers[0].latitude
          this.longitude = this.markers[0].longitude
        }
        console.log(this.latitude, this.longitude);
        // this.setMarker(markers);

      }
    });
  }

  onPageChange(event: number): void {
    this.pageNum = (event - 1) * this.pageSize;
    this.find();

  }

  inputLatLong($event) {

  }

  isOpen(isOpen: string) {
    return isOpen == 'N' ? false : true;
  }

  selectDay(day: string, isOpen: string) {
    this.data.openDay[day] = isOpen == 'N' ? 'Y' : 'N';
    console.log(this.data.openDay);
  }


  selectService(day: string, isOpen: string) {
    this.data.listService[day] = isOpen == 'N' ? 'Y' : 'N';
    console.log(this.data.listService);
  }

  isActiveType(isOpen: string) {
    return isOpen == 'N' ? false : true;
  }



  openImage(item, clinicName) {
    console.log(" clinicName " + clinicName);
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.urlImg = this.urlImageList + item.id;
    modalRef.componentInstance.clinicName = clinicName;

  }

  openPromotion(item) {
    const modalRef = this.modalService.open(NgbdModalPromotionContent);
    modalRef.componentInstance.urlImg = this.urlImagePromotion + item.id;
    modalRef.componentInstance.item = item;
  }

  openReview: boolean = false;
  review(item) {
    // this.search.clinicName = item.clinicName;
    const modalRef = this.modalService.open(ReviewComponent);
    modalRef.componentInstance.clinic = item;
    modalRef.componentInstance.optionList = true;
    modalRef.componentInstance.uid = this.currentUser.uid;
  }

  selectMarkerShow: boolean = false;
  selectMarker(item) {
    this.search.clinicName = item.clinicName;
    this.latitude = Number(item.latitude);
    this.longitude = Number(item.longitude);
    this.find();
    this.zoom = 15;
    this.selectMarkerShow = true;

    // this.map.setCenter({ lat: item.latitude, lng: item.longitude });
  }

  btnFind() {
    this.selectMarkerShow = false;
    this.zoom = 7;
    this.pageNum = 0;
    this.find();
  }

  subStringStartEnd(text: string) {
    if (text.charAt(0) == '"') {
      text = text.substr(1, text.length);
      text = text.substr(0, text.length - 1);
      return text;
    }
    return text;
  }


  backSearch() {
    this.search.clinicName = "";
    this.btnFind();
  }

  protected map: any;
  protected mapReady(map) {
    console.log(map);

    this.map = map;
  }

}
