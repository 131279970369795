import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CallhttpService } from 'app/callhttp.service';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleMapsComponent } from 'app/google-maps/google-maps.component';

@Component({
  selector: 'app-register-clinic',
  templateUrl: './register-clinic.component.html',
  styleUrls: ['./register-clinic.component.scss']
})

export class RegisterClinicComponent implements OnInit {
  @ViewChild('child') child: GoogleMapsComponent;

  latlng: any;
  fileData: File = null;
  images: any;
  urlImg: string;
  timeOpen = { hour: 0, minute: 0 };
  timeClose = { hour: 0, minute: 0 };
  data: any = {
    clinicId: "",
    packageId: "",
    uuid: "",
    password: "",
    openDay: {
      MON: "N",
      TUE: "N",
      WED: "N",
      THU: "N",
      FRI: "N",
      SAT: "N",
      SUN: "N",
    },
    listService: {
      service_1: "N",
      service_2: "N",
      service_3: "N",
      service_4: "N",
      service_5: "N",
      service_6: "N",
      service_7: "N",
      service_8: "N",
      service_9: "N",
      service_10: "N",
      service_11: "N",
      service_12: "N",
    },
    clinicSubProvince: "",
    clinicProvince: "",
    clinicDistrict: "",
    socialSecurity: "N"
  }
  socialSecurity:boolean=false;
  listpackage: any;



  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, private callService: CallhttpService, @Inject(APP_CONFIG) private config: AppConfig) {
    this.urlImg = this.config.urlImg;
  }

  currentUser: any;
  actionType: string = "REGISTER";
  isOpenTime = false;
  title = "ลงทะเบียน";
  ngOnInit() {
    // this.selectSocialSecurity();
    this.getListPackage();
    this.getMasterProvince();
    // sessionStorage.setItem('currentUser', JSON.stringify({ uid: 1, clinicId: 2 }))
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    console.log(this.currentUser);
    if (this.currentUser) {
      let clinicId = this.route.snapshot.paramMap.get('clinicId');
      console.log("clinicId "+clinicId);
      
      if (this.currentUser.gid == 1 && clinicId != this.currentUser.clinicId) {
          this.data.clinicId = clinicId;
      } else {
          this.data.clinicId = this.currentUser.clinicId;
      }
    }

    this.child.showSearch(true);

    if (!this.data.clinicId) {
      this.getUuid();
      this.isOpenTime = true;
    } else {
      this.actionType = "UPDATE";
      this.isOpenTime = false;
      this.title = "อัพเดตข้อมูล";
      // this.data.uuid = "5cc3c9a71f00d";
      this.getOne();

    }
  }

  inputLatLong($event) {
    this.latlng = $event;
    this.data.latitude = this.latlng.latitude;
    this.data.longitude = this.latlng.longitude;
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  uploadImg() {
    const formData = new FormData();
    formData.append('file', this.fileData);
    formData.append('uuid', this.data.uuid);
    this.http.post<any>(this.config.urlApi + "api/register/upload", formData)
      .subscribe(res => {
        console.log(res);
        alert('SUCCESS !!');

        this.listImg();
      })
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0];
    }
  }

  submit() {
    window.scrollTo(0, 0);
    this.data.timeClose = this.subStrTime(this.timeClose.hour) + ":" + this.subStrTime(this.timeClose.minute);
    this.data.timeOpen = this.subStrTime(this.timeOpen.hour) + ":" + this.subStrTime(this.timeOpen.minute);
    console.log(this.data);
    let msg = this.validatemandatory();
    if (msg != "") {
      alert("กรุณาระบุข้อมูล " + msg);
      return;
    };

    let emailPattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!emailPattern.test(this.data.email)) {
      alert("กรุณาตรวจสอบรูปแบบอีเมล");
      return;
    }

    if (this.data.password.length < 6) {
      alert("กรุณาระบุข้อมูลรหัสผ่านมากกว่า 6 ตัวอักษร");
      return;
    }
    // this.data.username = this.data.email;


    var r = confirm("ยืนยันบันทึกข้อมูลหรือไม่?");
    if (r) {
      if (this.actionType == "UPDATE") {
        let data = this.data;
        data.openDay = JSON.stringify(this.data.openDay);
        data.listService = JSON.stringify(this.data.listService);
        this.callService.getTracking("api/clinic/update", data).subscribe((res: any) => {
          // console.log(res);
          this.data.listService = JSON.parse(data.listService);
          this.data.openDay = JSON.parse(this.data.openDay);
          if (res.status) {
            alert("สำเร็จ");
            if (this.currentUser.gid == 1) {
              this.router.navigate(['approved-clinic', {}]);
            }
          } else {
            alert(res.msg);
          }

          // this.getOne();
          // this.back();
        });
      } else {
        let data = this.data;
        data.openDay = JSON.stringify(this.data.openDay);
        data.listService = JSON.stringify(this.data.listService);
        this.callService.getTracking("api/register", data).subscribe((res: any) => {
          // console.log(res);


          if (res.status) {
            //    this.login();
            alert("สำเร็จ");
            // this.getOne();
            this.back();
          } else {
            alert(res.msg);
          }
        });
      }
    }


  }

  cSlot() {

  }

  private getUuid() {
    this.callService.callService("api/getuuid", {}).subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        this.data.uuid = res.data;
      }
    });
  }

  selectDay(day: string, isOpen: string) {
    this.data.openDay[day] = isOpen == 'N' ? 'Y' : 'N';
    console.log(this.data.openDay);
  }

  isOpen(isOpen: string) {
    return isOpen == 'N' ? false : true;
  }

  selectService(day: string, isOpen: string) {
    this.data.listService[day] = isOpen == 'N' ? 'Y' : 'N';
    console.log(this.data.listService);
  }

  isActiveType(isOpen: string) {
    return isOpen == 'N' ? false : true;
  }

  listImg() {
    this.callService.callService("/masterfile", {
      uuid: this.data.uuid,
      table: "tab_clinic_img",
    }).subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        this.images = res.data;
      }
    });
  }

  getListPackage() {
    this.callService.callService("/masterfile", {
      uuid: this.data.uuid,
      table: "tab_package",
    }).subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        this.listpackage = res.data;
      }
    });
  }

  getOne() {
    this.callService.callService("/api/clinic/getone", {
      id: this.data.clinicId
    }).subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        this.data = res.data[0];

        this.data.socialSecurity = this.data.socialSecurity=='Y'?true:false;

        console.log(JSON.parse(this.data.openDay));
        this.data.listService = JSON.parse(this.subStringStartEnd(this.data.listService));
        this.data.openDay = JSON.parse(this.subStringStartEnd(this.data.openDay));
        let tOpen = this.data.timeOpen.split(":");
        this.timeOpen.hour = parseInt(tOpen[0]);
        this.timeOpen.minute = parseInt(tOpen[1]);
        console.log(this.timeOpen);

        let tClose = this.data.timeClose.split(":");
        this.timeClose.hour = parseInt(tClose[0]);
        this.timeClose.minute = parseInt(tClose[1]);

        this.listImg();
        this.onChangeLatLong();

        this.getMasterSybProvince();
        this.getMasterDistrict();
        // child.selectClinic(item);
      }
    });
  }

  subStringStartEnd(text: string) {
    if (text.charAt(0) == '"') {
      text = text.substr(1, text.length);
      text = text.substr(0, text.length - 1);
      return text;
    }
    return text;
  }

  back() {
    let params = {
      type: "clinic"
    };
    this.router.navigate(['signup', params]);
  }

  validatemandatory() {
    var validate = {
      clinicName: "ชื่อคลินิก",
      username: "ชื่อผู้ใช้งาน",
      clinicAddress: "ที่อยู่",
      clinicSubProvince: "อำเภอ/เขต",
      clinicProvince: "จังหวัด",
      clinicDistrict: "ตำบล",
      latitude: "ละติจูด",
      longitude: "ลองจิจูด",
      clinicTel: "เบอร์โทรศัพท์",
      email: "อีเมล",
      password: "รหัสผ่าน",
      timeOpen: "เวลาเปิด",
      timeClose: "ปิด"
    }
    let msg = "";
    for (let key in validate) {
      console.log(key);
      if (msg == "") {
        if (!this.data[key]) {
          msg = validate[key];
        }
      } else {
        return msg;
      }
    }
    return msg;
  }

  subStrTime(h) {
    let time = "0" + h;
    return time.substring(time.length - 2, time.length);
  }

  onChangeLatLong() {
    this.child.selectClinic(this.data);
  }

  deleteImage(item) {
    this.callService.getTracking("api/delete", {
      id: item.id,
      table: "tab_clinic_img",
      keyId: "id"
    }).subscribe((res: any) => {
      if (res.status) {
        this.listImg();
      } else {
        alert(res.message);
      }
    });
  }

  listProvince: any;
  getMasterProvince() {
    this.callService.getTracking("/masterfile", {
      table: "master_province",
    }).subscribe((res: any) => {
      if (res.status) {
        // this.listImg();
        this.listProvince = res.data;
        console.log(this.listProvince);

      } else {
        alert(res.message);
      }
    });
  }

  listSubProvince: any;
  getMasterSybProvince() {
    this.callService.getTracking("/masterfile", {
      table: "master_province t2 inner join  master_subprovince t1 on t1.provinceId = t2.id",
      "t2.name": this.data.clinicProvince
    }).subscribe((res: any) => {
      if (res.status) {
        // this.listImg();
        this.listSubProvince = res.data;
      } else {
        alert(res.message);
      }
    });
  }

  listDistrict: any;
  getMasterDistrict() {
    this.callService.getTracking("/masterfile", {
      table: "master_subprovince  t2 inner join master_district  t1 on t1.subprovinceId = t2.id ",
      "t2.name": this.data.clinicSubProvince
    }).subscribe((res: any) => {
      if (res.status) {
        // this.listImg();
        this.listDistrict = res.data;
      } else {
        alert(res.message);
      }
    });
  }

  latlonglinks: string;
  onGetLatLong() {
    let point = this.latlonglinks.match(/-?\d+\.\d+/g);
    this.data.latitude = point[0];
    this.data.longitude = point[1];
    console.log(point);
    this.onChangeLatLong();
  }

  async keyAlphabet(evt) {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    if (key == 8) {
      return;
    }
    key = String.fromCharCode(key);
    var regex = /[0-9a-zA-Z]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }


  selectSocialSecurity() {
  
    this.socialSecurity = !this.socialSecurity;
    console.log("selectSocialSecurity"+this.socialSecurity);
    this.data.socialSecurity = this.socialSecurity ? 'Y' : 'N';
  }

  
}
