import { Component, OnInit,Inject } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-banner-create',
  templateUrl: './banner-create.component.html',
  styleUrls: ['./banner-create.component.scss']
})
export class BannerCreateComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) { }

  ngOnInit() {
  }


  fileData: File = null;
  url:string;
  uploadImg() {
    const formData = new FormData();
    formData.append('file', this.fileData);
    formData.append('url', this.url);
    this.http.post<any>(this.config.urlApi + "api/banner/upload", formData)
      .subscribe(res => {
        console.log(res);
        alert('อัปโหลดสำเร็จ');
        this.back();
      })
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0];
    }
  }

  back(){
    this.router.navigate(['banner']);
  }
}
