import { Component, OnInit } from '@angular/core';
import { CallhttpService } from 'app/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-signup-admin',
    templateUrl: './signup-admin.component.html',
    styleUrls: ['./signup-admin.component.scss']
})
export class SignupAdminComponent implements OnInit {
    test : Date = new Date();
    focus;
    focus1;

    username: string = "";
    password: string = "";
    isLogin: boolean = false;
    clinicData:any;
    gid:string;
    params = {};
    type:string;
    constructor(private router: Router, private route: ActivatedRoute,
        private callService: CallhttpService) { }
    
    ngOnInit() {
        this.type = this.route.snapshot.paramMap.get('type');
        this.clinicData = JSON.parse(sessionStorage.getItem('currentUser'));
        if(this.clinicData){
            this.isLogin = true;
            this.gid = sessionStorage.getItem('gid');
        }else{
            this.isLogin = false;
            this.gid = '';
        }
    }

    login() {
        let data = {
            username: this.username,
            password: this.password
        }
        this.callService.getTracking("login", data).subscribe((res: any) => {
            if (res.status) {
                
                if(res.data.gid == 1){
                    this.clinicData = res.data;
                    sessionStorage.setItem('currentUser', JSON.stringify(res.data));
                    sessionStorage.setItem('gid', res.data.gid);
                    this.isLogin = true;
                    this.gid = res.data.gid;
                    alert("เข้าสู่ระบบสำเร็จ");
                    this.params = {};
                    this.router.navigate(['approved-clinic', this.params]);
                }else{
                    alert("ไม่พบชื่อผู้ใช้งานในระบบ");
                }
                
            } else {
                alert("ชื่อผู้ใช้งาน หรือรหัสผ่านไม่ถูกต้อง");
            }
        });
    }
}
