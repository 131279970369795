import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from './app-config.module';

@Injectable({
  providedIn: 'root'
})
export class CallhttpService {

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) { }

  getTracking(url:string,data: object) {
    return this.http.post(this.config.urlApi + url, data);
  }

  callService(url:string,data: object) {
    return this.http.post(this.config.urlApi + url, data);
  }
}
