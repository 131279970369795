import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { SpecialSearchComponent } from './special-search/special-search.component';
import { RegisterClinicComponent } from './register-clinic/register-clinic.component';
import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { ApprovedClinicComponent } from './approved-clinic/approved-clinic.component';
import { SignupAdminComponent } from './signup-admin/signup-admin.component';
import { ComponentsComponent } from './components/components.component';
import { SignuMemberComponent } from './signup-member/signup-member.component';
import { RegisterMemberComponent } from './register-member/register-member.component';
import { BannerComponent } from './banner/banner.component';
import { AnnounceComponent } from './announce/announce.component';
import { BannerCreateComponent } from './banner-create/banner-create.component';
import { AnnouncereateComponent } from './announce-create/announce-create.component';
import { AnnounceViewComponent } from './announce-view/announce-view.component';
import { PromotionListComponent } from './promotion-list/promotion-list.component';
import { PromotionCreateComponent } from './promotion-create/promotion-create.component';
import { PromotionComponent } from './promotion/promotion.component';
import { PromotionUseComponent } from './promotion-use/promotion-use.component';
import { AnnounceListComponent } from './announce-list/announce-list.component';
import { MapsComponent } from './maps/maps.component';
import { MainComponent } from './main/main.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'index', component: MainComponent },
  { path: 'user-profile', component: ProfileComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'admin', component: SignupAdminComponent },
  { path: 'signup-member', component: SignuMemberComponent },
  { path: 'register-member', component: RegisterMemberComponent },
  { path: 'landing', component: LandingComponent },
  { path: 'nucleoicons', component: NucleoiconsComponent },
  { path: 'special-search', component: SpecialSearchComponent },
  { path: 'register-clinic', component: RegisterClinicComponent },
  { path: 'google-maps', component: GoogleMapsComponent },
  { path: 'approved-clinic', component: ApprovedClinicComponent },
  { path: 'slide', component: ComponentsComponent },
  { path: 'banner', component: BannerComponent },
  { path: 'banner-create', component: BannerCreateComponent },
  { path: 'announce', component: AnnounceComponent },
  { path: 'announce-create', component: AnnouncereateComponent },
  { path: 'announce-view', component: AnnounceViewComponent },
  { path: 'announce-list', component: AnnounceListComponent },
  { path: 'promotion-list', component: PromotionListComponent },
  { path: 'promotion-create', component: PromotionCreateComponent },
  { path: 'my-promotion', component: PromotionComponent },
  { path: 'maps', component: MapsComponent },
  { path: '', redirectTo: 'index', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
