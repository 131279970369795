import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CallhttpService } from 'app/callhttp.service';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';

@Component({
  selector: 'app-approved-clinic',
  templateUrl: './approved-clinic.component.html',
  styleUrls: ['./approved-clinic.component.scss']
})
export class ApprovedClinicComponent implements OnInit {

  latitude: number = 13.7550437;
  longitude: number = 100.529726;
  search: any = {
    clinicName: "",
    clinicProvince: "",
    listService: "",
    isApproved: "N"
  };

  pageNum = 0;
  pageSize = 10;
  collectionSize = 0;

  inputLatlng: any = {
    "latitude": 13.7550437,
    "longitude": 100.529726
  }
  data: any;
  listService = [
    {
      name: "GENERAL DENTISTRY/ทันตกรรมทั่วไป, ขุดหินปูน, เคลือบฟลูออไรด์, ขัดฟัน",
      value: '"service_1":"Y"'
    },
    {
      name: "PEDODONTICS/ทันตกรรมสำหรับเด็ก",
      value: '"service_2":"Y"'
    },
    {
      name: "ORTHODONTICS/ทันตกรรมจัดฟัน",
      value: '"service_3":"Y"'
    },
    {
      name: "COSMETIC/ทันตกรรมเพื่อความงาม",
      value: '"service_4":"Y"'
    },
    {
      name: "PROSTHODONTICS/ทันตกรรมใส่ฟันทดแทน ฟันปลอม",
      value: '"service_5":"Y"'
    },
    {
      name: "IMPLANTS/ทันตกรรมรากเทียม",
      value: '"service_6":"Y"'
    },
    {
      name: "PERIODONTICS/ทันตกรรมโรคเหงือกปริทันต์",
      value: '"service_7":"Y"'
    },
    {
      name: "ENDODONTICS/ทันตกรรมรักษารากฟัน",
      value: '"service_8":"Y"'
    },
    {
      name: "ORAL SURGERY/ศัลยกรรมช่องปาก",
      value: '"service_9":"Y"'
    },
    {
      name: "3D SCAN/สแกน 3 มิติ",
      value: '"service_11":"Y"'
    },
    {
      name: "MAXILLOFACIAL SURGERY/ทันตกรรมผ่าตัดกระดูกขากรรไกรและใบหน้า",
      value: '"service_10":"Y"'
    },
    {
      name: "CLEAR ALIGNER/จัดฟันใส",
      value: '"service_12":"Y"'
    }
  ]
  currentUser: any;

  previewimg: string = "previewimg?table=tab_clinic_img&keyname=uuid&feildname=fileName&keyvalue=";
  urlImage: string;
  constructor(private router: Router, private route: ActivatedRoute, private callService: CallhttpService, @Inject(APP_CONFIG) private config: AppConfig) {
    this.urlImage = config.urlApi + this.previewimg;
    console.log(this.listService);

  }




  ngOnInit() {

    // this.search.clinicName = this.route.snapshot.paramMap.get('clinicName');
    // this.search.clinicProvince = this.route.snapshot.paramMap.get('clinicProvince');
    // this.search.clinicProvince = this.route.snapshot.paramMap.get('clinicProvince');

    // this.search.listService  =  this.listService[this.route.snapshot.paramMap.get('service')]['value'];
    this.currentUser = sessionStorage.getItem('currentUser');
    if (!this.currentUser) {
      alert("กรุณาล็อกอินเพิ่มเข้าสู่ระบบ");
      this.router.navigate(['home', {}]);
    }
    this.getListPackage();
    this.find();

  }
  listpackage: any;
  getListPackage() {
    this.callService.callService("/masterfile", {
      table: "tab_package",
    }).subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        this.listpackage = res.data;
      }
    });
  }


  find() {
    window.scrollTo(0, 0);
    this.pageNum = this.pageNum == 1 ? 0 : this.pageNum;
    this.callService.callService("api/clinic/find", {
      search: this.search,
      pageNum: this.pageNum,
      pageSize: this.pageSize
    }).subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        this.data = res.data.data;
        this.collectionSize = res.data.totalItem;
        let i = 0;
        this.data.forEach(item => {
          // this.data[i].openDay = JSON.parse(item.openDay);
          // this.data[i].listService = JSON.parse(item.listService);
          this.data[i].openDay = JSON.parse(this.subStringStartEnd(item.openDay));
          this.data[i].listService = JSON.parse(this.subStringStartEnd(item.listService));
          i++;
        });

        console.log(this.data);

      }
    });
  }

  inputLatLong($event) {

  }

  isOpen(isOpen: string) {
    return isOpen == 'N' ? false : true;
  }

  selectDay(day: string, isOpen: string) {
    this.data.openDay[day] = isOpen == 'N' ? 'Y' : 'N';
    console.log(this.data.openDay);
  }


  selectService(day: string, isOpen: string) {
    this.data.listService[day] = isOpen == 'N' ? 'Y' : 'N';
    console.log(this.data.listService);
  }

  isActiveType(isOpen: string) {
    return isOpen == 'N' ? false : true;
  }

  approved(item: any) {
    var r = confirm("ยืนยันอนุมัติข้อมูลหรือไม่?");
    if (r) {
      this.callService.callService("api/clinic/approved", { clinicId: item.clinicId }).subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          alert("อนุมัติสำเร็จ");
          this.find();
        }
      });
    }
  }

  inactive(item: any, isActive: string): void {
    var r = confirm("ยืนยันข้อมูลหรือไม่?");
    if (r) {
      this.callService.callService("api/clinic/inactive", { clinicId: item.clinicId, isActive: isActive }).subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          alert("อัพเดตสำเร็จ");
          this.find();
        }
      });
    }
  }


  onPageChange(event: number): void {
    this.pageNum = (event - 1) * this.pageSize;
    this.find();
  }



  pay(item) {
    var r = confirm("ยืนยันชำระเงินหรือไม่?");
    if (r) {
      this.callService.callService("api/clinic/pay", {
        clinicId: item.clinicId,
        promotionSlot: item.package[0].promotionSlot,
        promotionMonth: item.package[0].month
      }).subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          alert("สำเร็จ");
          this.find();
        }
      });
    }
  }

  updatePackage(item) {
    var r = confirm("ยืนยันข้อมูลหรือไม่?");
    if (r) {
      this.callService.callService("api/clinic/updatedata", {
        clinicId: item.clinicId,
        packageId: item.packageId
      }).subscribe((res: any) => {
        console.log(res);
        if (res.status) {
          alert("สำเร็จ");
          this.find();
        }
      });
    }
  }

  subStringStartEnd(text: string) {
    if (text.charAt(0) == '"') {
      text = text.substr(1, text.length);
      text = text.substr(0, text.length - 1);
      return text;
    }
    return text;
  }

  updateClinic(item) {
    let params = {
      clinicId: item.clinicId
    }
    this.router.navigate(['register-clinic', params]);
  }

}
