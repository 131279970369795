import { Component, OnInit } from '@angular/core';
import { CallhttpService } from 'app/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EmailValidator } from '@angular/forms';

@Component({
    selector: 'app-register-member',
    templateUrl: './register-member.component.html',
    styleUrls: ['./register-member.component.scss']
})
export class RegisterMemberComponent implements OnInit {
    test: Date = new Date();
    focus;
    focus1;

    username: string = "";
    password: string = "";
    isLogin: boolean = false;
    clinicData: any;
    gid: string;
    params = {};
    type: string;

    data = {
        firstName: "",
        lastName: "",
        sex: "N",
        birthDate: "",
        email: "",
        username: "",
        password: ""
    };

   
    constructor(private router: Router, private route: ActivatedRoute,
        private callService: CallhttpService) { }

    ngOnInit() {
      
       
    }

    register() {
        let msg = this.validatemandatory();
        if(msg != ""){
            alert("กรุณาระบุข้อมูล "+msg);
            return;
        };
        let emailPattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if(!emailPattern.test(this.data.email)){
            alert("กรุณาตรวจสอบรูปแบบอีเมล");
            return;
        }
        console.log( emailPattern.test("g@gmail.com")?"true":"false");
        console.log( emailPattern.test("g")?"true":"false");

        if(this.data.password.length < 6){
            alert("กรุณาระบุข้อมูลรหัสผ่านมากกว่า 6 ตัวอักษร");
            return;
        }

        // this.data.username = this.data.email;
        var r = confirm("ยืนยันข้อมูลหรือไม่?");
        if (r) {
            this.callService.getTracking("registermember", this.data).subscribe((res: any) => {
                if (res.status) {
                    //    this.login();
                    let params = {
                        type: "member"
                    };
                    this.router.navigate(['signup-member', params]);
                } else {
                    alert(res.msg);
                }
            });
            console.log();
        }
    }

    login() {
        let data = {
            username: this.data.username,
            password: this.data.password
        }
        this.callService.getTracking("login", data).subscribe((res: any) => {
            if (res.status) {
                this.clinicData = res.data;
                sessionStorage.setItem('currentUser', JSON.stringify(res.data));
                sessionStorage.setItem('gid', res.data.gid);
                this.isLogin = true;
                this.gid = res.data.gid;
                if (res.data.gid == 1) {
                    this.params = {};
                    this.router.navigate(['approved-clinic', this.params]);
                }
                // this.router.navigate(['home', this.params]);
                alert("เข้าสู่ระบบสำเร็จ");
                //     let params = {
                //     type: "member"
                //   };
                //   this.router.navigate(['signup-member', params]);
            } else {
                alert(res.message);
            }
        });
    }

    validatemandatory() {
        var validate = {
            firstName: "ชื่อ",
            lastName: "นามสกุล",
            sex : "เพศ",
            birthDate: "วันเกิด",
            email: "อีเมล",
            username:"ชื่อผู้ใช้งาน",
            password: "รหัสผ่าน",
        }
        let msg = "";
        for (let key in validate) {
            console.log(key);
            if (msg == "") {
                if (!this.data[key]) {
                    msg = validate[key];
                }
            }else{
                return msg;
            }
        }
        return msg;
    }
}
