import { NgModule, InjectionToken } from '@angular/core';
import { environment } from '../environments/environment';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
  urlApi: string;
  urlImg : string;
  // permissionCode: {[key: string]: string};
  // group: {[key: string]: string};
  // companyTypeCust : string;
  // gidSystemAdmin : string;
}

export const APP_DI_CONFIG: AppConfig = {
  urlApi: environment.urlApi,
  urlImg : environment.urlImg,
  // permissionCode: environment.permissionCode,
  // group : environment.group,
  // companyTypeCust  : environment.companyTypeCust,
  // gidSystemAdmin : environment.GID_SYSTEM_ADMIN
};

@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: APP_DI_CONFIG
  }]
})
export class AppConfigModule {}
