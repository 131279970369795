import { Component, OnInit, Inject } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { ActivatedRoute, Router } from '@angular/router';
import { CallhttpService } from 'app/callhttp.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalContent } from 'app/special-search/special-search.component';

@Component({
  selector: 'app-announce-view',
  templateUrl: './announce-view.component.html',
  styleUrls: ['./announce-view.component.scss']
})
export class AnnounceViewComponent implements OnInit {

  fileData: File = null;
  url: string;
  topic: string;
  type: string = "";
  uuid: string;
  images:any;
  detail:string;
  constructor(private router: Router, private route: ActivatedRoute,
    private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig,
    private callhttpService: CallhttpService, private modalService: NgbModal) { }
  item:any;
  previewimg = "previewimg?table=tab_announce_img&keyname=id&feildname=fileName&keyvalue=";
  ngOnInit() {
    
   
    this.previewimg = this.config.urlApi + this.previewimg;
    this.getItem();
  }
  id;
  getItem(){
    this.item = sessionStorage.getItem('viewactivity')?JSON.parse(sessionStorage.getItem('viewactivity')):'';
    console.log(this.item);
    if(this.item){
      this.id = this.item.id;
      this.url = this.item.link;
      this.uuid = this.item.uuid;
      this.topic = this.item.topic;
      this.type = this.item.type;
      this.detail = this.item.detail;
      this.listImg();
    }else{
      this.getUuid();
    }
  }

 
  uploadImg() {
    const formData = new FormData();
    formData.append('file', this.fileData);
    formData.append('link', this.url);
    formData.append('topic', this.topic);
    formData.append('type', this.type);
    formData.append('uuid', this.uuid);
    formData.append('detail', this.detail);
    formData.append('id', this.id);
    this.http.post<any>(this.config.urlApi + "api/announce/upload", formData)
      .subscribe(res => {
        console.log(res);
        alert('สำเร็จ');
        this.back();
      })
  }

  uploadImgSub() {
    const formData = new FormData();
    formData.append('file', this.fileData);
    formData.append('uuid', this.uuid);
    this.http.post<any>(this.config.urlApi + "api/announceimg/upload", formData)
      .subscribe(res => {
        console.log(res);
        alert('อัปโหลดสำเร็จ');
        this.listImg();
        // this.back();
      })
  }

  listImg() {
    this.callhttpService.callService("/masterfile", {
      uuid: this.uuid,
      table: "tab_announce_img",
    }).subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        this.images = res.data;
      }
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0];
    }
  }

  back() {
    this.router.navigate(['home']);
  }

  deleteImage(item){
    this.callhttpService.getTracking("api/delete", {
      id: item.id,
      table: "tab_announce_img",
      keyId: "id"
    }).subscribe((res: any) => {
      if (res.status) {
        this.listImg();
      } else {
        alert(res.message);
      }
    });
  }

  private getUuid() {
    this.callhttpService.callService("api/getuuid", {}).subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        this.uuid = res.data;
      }
    });
  }

  openImage(item,clinicName) {
    console.log(" clinicName "+clinicName);
    
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.urlImg = this.previewimg + item.id;
    modalRef.componentInstance.clinicName = clinicName;
  }

}
