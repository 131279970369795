import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './home/home.module';
import { SpecialSearchComponent, NgbdModalContent, NgbdModalPromotionContent } from './special-search/special-search.component';
import { RegisterClinicComponent } from './register-clinic/register-clinic.component';
import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';
import { AppConfigModule } from './app-config.module';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ApprovedClinicComponent } from './approved-clinic/approved-clinic.component';
import { SignupAdminComponent } from './signup-admin/signup-admin.component';
import { TabAnnounceComponent } from './tab-announce/tab-announce.component';
import { SignuMemberComponent } from './signup-member/signup-member.component';
import { RegisterMemberComponent } from './register-member/register-member.component';
import { BannerComponent } from './banner/banner.component';
import { BannerCreateComponent } from './banner-create/banner-create.component';
import { AnnounceComponent } from './announce/announce.component';
import { AnnouncereateComponent } from './announce-create/announce-create.component';
import { AnnounceViewComponent } from './announce-view/announce-view.component';
import { PromotionListComponent } from './promotion-list/promotion-list.component';
import { PromotionCreateComponent } from './promotion-create/promotion-create.component';
import { PromotionComponent } from './promotion/promotion.component';
import { PromotionUseComponent } from './promotion-use/promotion-use.component';
import { AnnounceListComponent } from './announce-list/announce-list.component';
import { MapsComponent } from './maps/maps.component';
import { ReviewComponent } from './review/review.component';
import { MainComponent } from './main/main.component';

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    SignupAdminComponent,
    LandingComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    SpecialSearchComponent,
    RegisterClinicComponent,
    GoogleMapsComponent,
    ApprovedClinicComponent,
    TabAnnounceComponent,
    NgbdModalContent,
    NgbdModalPromotionContent,
    SignuMemberComponent,
    RegisterMemberComponent,
    BannerComponent,
    BannerCreateComponent,
    AnnounceComponent,
    AnnouncereateComponent,
    AnnounceViewComponent,
    PromotionListComponent,
    PromotionCreateComponent,
    PromotionComponent,
    PromotionUseComponent,
    AnnounceListComponent,
    MapsComponent,
    ReviewComponent,
    MainComponent
  ],
  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCnaWwL4SmD7b8kksI0-xlBfK2rV4IM1lk',
      libraries: ['places','geometry']
    }),
    HttpClientModule,
    AppConfigModule
  ],
  entryComponents: [NgbdModalContent,NgbdModalPromotionContent],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
