/// <reference types="@types/googlemaps" />
import { Component, OnInit, NgZone, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';

declare var google: any;
@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit {
  title: string = 'AGM project';
  latitude: number = 13.7550437;
  longitude: number = 100.529726;
  zoom: number = 12;
  address: string;
  private geoCoder;

  // markers labels
  alphabeticLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  labelIndex: number = 0;

  label: any = {
    color: '#ffffff',
    fontWeight: 'bold',
    text: "Lorem Ipsum",
  };
  // markers: any[] = [];


  @ViewChild('search')
  public searchElementRef: ElementRef;

  @Input()  markers: any[] = [];
  @Output() outlatlng: EventEmitter<any> = new EventEmitter();

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) { }

  show = false;


  ngOnInit() {
    // let i = 0;
    // this.zoom = 12;
    // while (i < 5) {
    //   this.label.text = this.alphabeticLabels[this.labelIndex++ % this.alphabeticLabels.length]
    //   this.markers.push({
    //     "latitude": this.latitude + i,
    //     "longitude": this.longitude + i,
    //     // "url" : "http://maps.gstatic.com/mapfiles/markers2/icon_green.png",
    //     "label": this.label
    //   });
    //   i++;
    // }
    // console.log(this.markers, this.latitude);

    // this.latitude = this.markers[0].latitude;
    // this.longitude = this.markers[0].longitude;
    // // this.label = {
    // //   color: '#ffffff',
    // //   fontWeight: 'bold',
    // //   text: "Lorem Ipsum"
    // // }
    // console.log(this.markers, this.latitude);
    // this.mapsAPILoader.load().then(() => {
    //   this.setCurrentLocation();
    //   // this.geoCoder = new google.maps.Geocoder;

    //   // let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
    //   //   types: ["address"]
    //   // });
    //   // autocomplete.addListener("place_changed", () => {
    //   //   this.ngZone.run(() => {
    //   //     //get the place result
    //   //     let place: google.maps.places.PlaceResult = autocomplete.getPlace();

    //   //     //verify result
    //   //     if (place.geometry === undefined || place.geometry === null) {
    //   //       return;
    //   //     }

    //   //     //set latitude, longitude and zoom
    //   //     this.latitude = place.geometry.location.lat();
    //   //     this.longitude = place.geometry.location.lng();
    //   //     console.log(place);

    //   //     this.zoom = 12;


    //   //   });
    //   // });
    // });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    this.markers.push({
      "latitude": 6.582895199999999,
      "longitude": 101.2829441,
      "url" : "http://maps.gstatic.com/mapfiles/markers2/icon_green.png",
      "label": "A"
    });
    // let i = 0;
    // this.zoom = 12;
    // while (i < 5) {
    //   this.markers.push({
    //     "latitude": this.latitude + i,
    //     "longitude": this.longitude + i,
    //     "label": this.alphabeticLabels[i++ % this.alphabeticLabels.length]
    //   });
    //   i++;
    // }

    console.log(this.markers);

    // if ('geolocation' in navigator) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     this.latitude = position.coords.latitude;
    //     this.longitude = position.coords.longitude;
    //     this.zoom = 8;
    //     this.getAddress(this.latitude, this.longitude);
    //   });
    // }
  }


  // markerDragEnd($event: MouseEvent) {
  //   console.log($event);
  //   this.latitude = $event.coords.lat;
  //   this.longitude = $event.coords.lng;
  //   this.getAddress(this.latitude, this.longitude);
  // }

  getAddress(latitude, longitude) {
    this.outlatlng.emit({
      "latitude": this.latitude,
      "longitude": this.longitude
    });
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.markers.push({
            "latitude": this.latitude,
            "longitude": this.longitude,
            "label": "ss"
          });
          // let i = 0;
          // while (i < 3) {
          //   this.markers.push({
          //     "latitude": this.latitude + i,
          //     "longitude": this.longitude + i,
          //     "label": this.alphabeticLabels[i++ % this.alphabeticLabels.length]
          //   });
          //   i++;
          // }

          console.log(this.markers);


        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  setAllMarker(markers: any[]) {
    this.markers =  markers;
    this.latitude = this.markers[0].latitude;
    this.longitude = this.markers[0].longitude;
  }

  showSearch(show) {
    this.show = show;
  }


}
