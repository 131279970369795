import { Component, OnInit } from '@angular/core';
import { CallhttpService } from 'app/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    test : Date = new Date();
    focus;
    focus1;

    username: string = "";
    password: string = "";
    isLogin: boolean = false;
    clinicData:any;
    gid:string;
    params = {};
    type:string;
    constructor(private router: Router, private route: ActivatedRoute,
        private callService: CallhttpService) { }
    
    ngOnInit() {
        this.type = this.route.snapshot.paramMap.get('type');
        this.clinicData = JSON.parse(sessionStorage.getItem('currentUser'));
        if(this.clinicData){
            this.isLogin = true;
            this.gid = sessionStorage.getItem('gid');
        }else{
            this.isLogin = false;
            this.gid = '';
        }
    }

    login() {
        let data = {
            username: this.username,
            password: this.password
        }
        this.callService.getTracking("login", data).subscribe((res: any) => {
            if (res.status) {
                this.clinicData = res.data;
                sessionStorage.setItem('currentUser', JSON.stringify(res.data));
                sessionStorage.setItem('gid', res.data.gid);
                this.isLogin = true;
                this.gid = res.data.gid;
                this.params = {
                    clinicId : res.data.clinicId
                }
                if(res.data.gid == 2){
                    this.router.navigate(['register-clinic', this.params]);
                    alert("เข้าสู่ระบบสำเร็จ");
                }else{
                    alert("ไม่พบชื่อผู้ใช้งานในระบบ");
                }
            } else {
                alert(res.msg);
            }
        });
    }

    forgot(){
        alert("กรุณาติดต่อผู้ดูและระบบ");
    }
}
