import { Component, OnInit,Inject } from '@angular/core';
import { CallhttpService } from 'app/callhttp.service';

import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-promotion-use',
  templateUrl: './promotion-use.component.html',
  styleUrls: ['./promotion-use.component.scss']
})
export class PromotionUseComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,private callhttpService: CallhttpService, @Inject(APP_CONFIG) private config: AppConfig) { 
    this.previewimg = this.config.urlApi + this.previewimg;
  }
  slide;
  currentUser ;
  code:string="";
  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.listBaner();
  }
  previewimg = "previewimg?table=tab_clinic_promotion&keyname=id&feildname=fileName&keyvalue=";
  listBaner() {
    sessionStorage.removeItem('itemselect');
    this.callhttpService.getTracking("masterfile", {
      table: "tab_clinic_promotion_code t1 INNER JOIN tab_clinic_promotion t2 on t2.id=t1.promoId inner join tab_clinic t3 on t3.uuid = t1.uuid inner join tab_user t4 on t4.uid = t1.uid ",
      code : this.code,
      't1.uuid' : this.currentUser.uuid,
    }).subscribe((res: any) => {
      if (res.status) {
        this.slide = res.data;
      } else {
        alert(res.message);
      }
    });
  }

  usecode(item){
    this.callhttpService.getTracking("api/promotioncode/update", {
      code: item.code,
      table: "tab_clinic_promotion_code",
    }).subscribe((res: any) => {
      if (res.status) {
        this.listBaner();
      } else {
        alert(res.message);
      }
    });
  }

  edit(item){
    sessionStorage.setItem('itemselect',JSON.stringify(item));
    this.router.navigate(['promotion-create']);
  }

}
