import { Component, OnInit } from '@angular/core';
import { CallhttpService } from 'app/callhttp.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  constructor(private callhttpService: CallhttpService) { }
  slide;
  ngOnInit() {
    this.listBaner();
  }

  listBaner() {
    this.callhttpService.getTracking("masterfile", {
      table: "tab_banner",
    }).subscribe((res: any) => {
      if (res.status) {
        this.slide = res.data;
      } else {
        alert(res.message);
      }
    });
  }

  delete(item){
    this.callhttpService.getTracking("api/banner/delete", {
      id: item.id,
    }).subscribe((res: any) => {
      if (res.status) {
        this.listBaner();
      } else {
        alert(res.message);
      }
    });
  }
}
