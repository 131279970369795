import { Component, OnInit, Inject } from '@angular/core';
import { CallhttpService } from 'app/callhttp.service';

import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-promotion-list',
  templateUrl: './promotion-list.component.html',
  styleUrls: ['./promotion-list.component.scss']
})
export class PromotionListComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,private callhttpService: CallhttpService, @Inject(APP_CONFIG) private config: AppConfig) { 
    this.previewimg = this.config.urlApi + this.previewimg;
  }
  slide;
  currentUser ;
  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.listBaner();
  }
  previewimg = "previewimg?table=tab_clinic_promotion&keyname=id&feildname=fileName&keyvalue=";
  listBaner() {
    sessionStorage.removeItem('itemselect');
    this.callhttpService.getTracking("masterfile", {
      table: "tab_clinic_promotion",
      uuid : this.currentUser.gid == 2 ? this.currentUser.uuid : ''
    }).subscribe((res: any) => {
      if (res.status) {
        this.slide = res.data;
      } else {
        alert(res.message);
      }
    });
  }

  delete(item){
    this.callhttpService.getTracking("api/delete", {
      id: item.id,
      table: "tab_clinic_promotion",
      keyId: "id"
    }).subscribe((res: any) => {
      if (res.status) {
        this.listBaner();
      } else {
        alert(res.message);
      }
    });
  }

  edit(item){
    sessionStorage.setItem('itemselect',JSON.stringify(item));
    this.router.navigate(['promotion-create']);
  }
}
