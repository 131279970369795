import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    isLogin: boolean = false;
    constructor(public location: Location, private element: ElementRef, private router: Router) {
        this.sidebarVisible = false;
    }
    currentUser;
    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (sessionStorage.getItem('currentUser')) {
            this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            this.isLogin = true;
        }

    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        // console.log(toggleButton, 'toggle');

        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if (titlee === '/home') {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee === '/documentation') {
            return true;
        }
        else {
            return false;
        }
    }

    checkLogin() {
        this.isLogin = false;
        if (sessionStorage.getItem('currentUser')) {
            this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            this.isLogin = true;
        }
        return this.isLogin;;
    }

    checkMember() {
        if (sessionStorage.getItem('currentUser')) {
            this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            return this.currentUser.gid == 3 ? true : false;
        }else{
            return false;
        }
    }

    params = {};
    loginpage(type: string) {
        console.log("loginpage", type);

        this.params = {
            type: type
        };
        this.router.navigate(['signup', this.params]);
    }

    loginpagemember(type: string) {
        console.log("loginpage", type);

        this.params = {
            type: type
        };
        this.router.navigate(['signup-member', this.params]);
    }

    logout(){
        sessionStorage.clear();
        this.router.navigate(['home', {}]);
    }

    backTo(){
        if(this.currentUser.gid == 1){
            this.params = {};
            this.router.navigate(['approved-clinic', this.params]);
        }
        if(this.currentUser.gid == 2){
            this.params = {};
            this.router.navigate(['register-clinic', this.params]);
        }
    }
}
