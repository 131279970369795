import { Component, OnInit, Inject } from '@angular/core';
import { CallhttpService } from 'app/callhttp.service';
import { APP_CONFIG, AppConfig } from 'app/app-config.module';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-announce',
  templateUrl: './announce.component.html',
  styleUrls: ['./announce.component.scss']
})
export class AnnounceComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute,private callhttpService: CallhttpService, @Inject(APP_CONFIG) private config: AppConfig) { }
  slide;
  previewimg = "previewimg?table=tab_announce&keyname=id&feildname=fileName&keyvalue=";
  previewimgSub = "previewimg?table=tab_announce_img&keyname=uuid&feildname=fileName&keyvalue=";
  search = {
    table: "tab_announce",
    topic: "",
    type: "",
  }
  ngOnInit() {
    this.listBaner(this.search);
    this.previewimg = this.config.urlApi + this.previewimg;
    this.previewimgSub = this.config.urlApi + this.previewimgSub;
    
  }

  listBaner(search) {
    sessionStorage.removeItem('itemselect');
    this.callhttpService.getTracking("masterfile", search).subscribe((res: any) => {
      if (res.status) {
        this.slide = res.data;
      } else {
        alert(res.message);
      }
    });
  }

  find(){
    this.listBaner(this.search);
  }

  delete(item){
    this.callhttpService.getTracking("api/delete", {
      id: item.id,
      table: "tab_announce",
      keyId: "id"
    }).subscribe((res: any) => {
      if (res.status) {
        this.listBaner(this.search);
      } else {
        alert(res.message);
      }
    });
  }

  edit(item){
    sessionStorage.setItem('itemselect',JSON.stringify(item));
    this.router.navigate(['announce-create']);
  }
}
