import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CallhttpService } from 'app/callhttp.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  @Input() clinic: any;

  reviews: any;
  @Input() optionList: boolean = true;
  detail: string;
  score = 0;
  @Input() uid: string;

  constructor(public activeModal: NgbActiveModal, private callhttpService: CallhttpService) { }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.callhttpService.callService("/masterfile", {
      uuid: this.clinic.uuid,
      table: "tab_clinic_review",
      order: "order by id desc"
    }).subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        this.reviews = res.data;
      }
    });
  }

  writeReview() {
    this.optionList = false;
  }


  submit() {
    this.callhttpService.callService("review/insert", {
      uuid: this.clinic.uuid,
      detail: this.detail,
      score: this.score,
      uid: this.score
    }).subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        // this.reviews = res.data;
        this.optionList = true;
        this.getList();
      }
    });
  }

}
