import { Component, OnInit } from '@angular/core';
import { CallhttpService } from 'app/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    test : Date = new Date();
    visitor:number;
    constructor(
        private callService: CallhttpService,
        private router: Router, private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.countVisitor();
    }

    countVisitor(){
            this.callService.getTracking("visitor/count", {}).subscribe((res: any) => {
                if (res.status) {
                    this.visitor = res.data[0]['count'];
                } else {
                    alert(res.message);
                }
            });
    }
    
    viewListAll(type) {
        this.router.navigate(['announce-list', {
            type: type
        }]);
    }

}
