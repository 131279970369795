import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CallhttpService } from 'app/callhttp.service';
import { AppConfig, APP_CONFIG } from 'app/app-config.module';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    public get callService(): CallhttpService {
        return this._callService;
    }
    public set callService(value: CallhttpService) {
        this._callService = value;
    }
    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;
    params = {};
    clinicName: string = "";
    clinicProvince: string = "";
    service:string = "";
    username: string = "";
    password: string = "";
    isLogin: boolean = false;
    clinicData: any;
    countClinic: string;
    gid;
    session: string = "homme";
    announce = [];
    announce2 = [];
    urlImage: string;
    previewimg = "previewimg?table=tab_announce_img&keyname=uuid&feildname=fileName&keyvalue=";

    listService = [
        {
          name: "GENERAL DENTISTRY/ทันตกรรมทั่วไป, ขุดหินปูน, เคลือบฟลูออไรด์, ขัดฟัน",
          value: '"service_1":"Y"'
        },
        {
          name: "PEDODONTICS/ทันตกรรมสำหรับเด็ก",
          value: '"service_2":"Y"'
        },
        {
          name: "ORTHODONTICS/ทันตกรรมจัดฟัน",
          value: '"service_3":"Y"'
        },
        {
          name: "COSMETIC/ทันตกรรมเพื่อความงาม",
          value: '"service_4":"Y"'
        },
        {
          name: "PROSTHODONTICS/ทันตกรรมใส่ฟันทดแทน ฟันปลอม",
          value: '"service_5":"Y"'
        },
        {
          name: "IMPLANTS/ทันตกรรมรากเทียม",
          value: '"service_6":"Y"'
        },
        {
          name: "PERIODONTICS/ทันตกรรมโรคเหงือกปริทันต์",
          value: '"service_7":"Y"'
        },
        {
          name: "ENDODONTICS/ทันตกรรมรักษารากฟัน",
          value: '"service_8":"Y"'
        },
        {
          name: "ORAL SURGERY/ศัลยกรรมช่องปาก",
          value: '"service_9":"Y"'
        },
        {
          name: "3D SCAN/สแกน 3 มิติ",
          value: '"service_11":"Y"'
        },
        {
          name: "MAXILLOFACIAL SURGERY/ทันตกรรมผ่าตัดกระดูกขากรรไกรและใบหน้า",
          value: '"service_10":"Y"'
        },
        {
          name: "CLEAR ALIGNER/จัดฟันใส",
          value: '"service_12":"Y"'
        }
      ]

    constructor(private router: Router, private route: ActivatedRoute,
        private _callService: CallhttpService, @Inject(APP_CONFIG) private config: AppConfig) { }

    ngOnInit() {
        this.urlImage = this.config.urlApi + this.previewimg;
        this.countAllClinic();
        this.clinicData = JSON.parse(sessionStorage.getItem('currentUser'));


        if (this.clinicData) {
            this.isLogin = true;
            this.gid = sessionStorage.getItem('gid');
        } else {
            this.isLogin = false;
            this.gid = '';
        }
        this.loadAnn();
        this.loadAnn2();
        this.keepVisitor();
    }

    find() {
        this.params = {};
        this.params['clinicName'] = this.clinicName;
        this.params['clinicProvince'] = this.clinicProvince;
        this.params['service'] = this.service;
        this.router.navigate(['special-search', this.params]);
    }

    login() {
        let data = {
            username: this.username,
            password: this.password
        }
        this.callService.getTracking("login", data).subscribe((res: any) => {
            if (res.status) {
                this.clinicData = res.data;
                sessionStorage.setItem('currentUser', JSON.stringify(res.data));
                sessionStorage.setItem('gid', res.data.gid);
                this.isLogin = true;
                this.gid = res.data.gid;
                if (res.data.gid == 1) {
                    this.params = {};
                    this.router.navigate(['approved-clinic', this.params]);
                }
                alert("เข้าสู่ระบบสำเร็จ");
            } else {
                alert(res.message);
            }
        });
    }

    goAdmin() {
        this.params = {};
        this.router.navigate(['approved-clinic', this.params]);
    }

    goUpdateClinic() {
        this.params = {};
        this.clinicData = JSON.parse(sessionStorage.getItem('currentUser'));
        if (this.clinicData) {
            this.params['clinicId'] = this.clinicData.clinicId;
            this.router.navigate(['register-clinic', this.params]);
        } else {
            alert("กรุณาล็อกอินเข้าสู่ระบบ หรือลงทะเบียน");
        }
    }

    findByService(serivesIndex: number) {
        this.params = {};
        this.params['clinicName'] = this.clinicName;
        this.params['service'] = serivesIndex;
        this.router.navigate(['special-search', this.params]);
    }


    countAllClinic() {
        this.callService.getTracking("/api/clinic/count", {}).subscribe((res: any) => {
            if (res.status) {
                this.countClinic = res.data[0]['count'];


            } else {
                alert(res.message);
            }
        });
    }

    logout() {
        sessionStorage.clear();
        this.isLogin = false;
        this.gid = '';
        this.router.navigate(['home', {}]);
    }

    loadAnn() {
        this.callService.getTracking("/masterfile", { "table": "tab_announce", "type": "1" }).subscribe((res: any) => {
            if (res.status) {
                this.announce = res.data;
            } else {
                alert(res.message);
            }
        });
    }

    loadAnn2() {
        this.callService.getTracking("/masterfile", { "table": "tab_announce", "type": "2" }).subscribe((res: any) => {
            if (res.status) {
                this.announce2 = res.data;
            } else {
                alert(res.message);
            }
        });
    }

    keepVisitor() {
        if (!sessionStorage.getItem('session')) {
            this.callService.getTracking("visitor/insert", {}).subscribe((res: any) => {
                if (res.status) {
                    sessionStorage.setItem('session', this.session);
                } else {
                    alert(res.message);
                }
            });
        }
    }

    viewActivity(item) {
        sessionStorage.setItem('viewactivity', JSON.stringify(item));
        this.router.navigate(['announce-view', {}]);
    }

    viewListAll(type) {
        this.router.navigate(['announce-list', {
            type: type
        }]);
    }

    allFind(){
        this.router.navigate(['special-search',{ all:'Y' }]);
    }


}
